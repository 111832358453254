import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { Power2, gsap } from 'gsap';

import { Scroll } from './_app/cuchillo/scroll/Scroll';
import { VSticky } from './_app/cuchillo/scroll/insiders/VSticky';
import { VScaleZero } from './_app/cuchillo/scroll/insiders/VScaleZero';
import { VScale } from './_app/cuchillo/scroll/insiders/VScale';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';
import { VDisplace } from './_app/cuchillo/scroll/insiders/VDisplace';
import { VInsiderMask } from './_app/cuchillo/scroll/insiders/VInsiderMask';

import { ScrollItem__Form } from './scroll/ScrollItem__Form';
import { ScrollItem__Assistants } from './scroll/ScrollItem__Assistants';

import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Basics, isDebug, isTouch } from './_app/cuchillo/core/Basics';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import { Interaction, MrInteraction } from './_app/cuchillo/core/Interaction';
import { GetBy } from './_app/cuchillo/core/Element';
import { Metrics } from './_app/cuchillo/core/Metrics';
import { Statics } from './_app/cuchillo/utils/Statics';
import Keyboard from './_app/cuchillo/core/Keyboard';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';

import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import Page from './_app/cuchillo/pages/Page';
import Default from './pages/Default';
import Programme from './pages/Programme';
import Legal from './pages/Legal';

import BG from './_app/cuchillo/layout/Background';
import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import Header from './layout/Header';
import Loading from './layout/Loading';
// import ScrollTop from './layout/ScrollTop';
import Wrap from './layout/Wrap';

import { ControllerWindow } from './_app/cuchillo/windows/ControllerWindow';
import Win from './_app/cuchillo/windows/Window';
import Preloader from './layout/Preloader';
// import Sidemenu from './layout/Sidemenu';
import Language from './_app/cuchillo/utils/Language';
import CMP from './windows/Cmp';
// import WebGLSketch from './_app/cuchillo/3D/WebGLSketch';

export default class Main {
  static stats;

  static init() {
    gsap.ticker.fps(60);

    Basics.id = 'EIT_v001'; // ID para cookies   
    Language.init();

    Header.init();
    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: true }) // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
    ControllerWindow.init(); // Control ventanas

    BG.init(CMS_COLORS); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
    InterfaceCanvas.init(); // Canvas de interface, se usa con Cursor
    //CMP.init(); // Checkea y saca el aviso de cookies

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    LoaderController.update = progress => Preloader.update(progress);
    LoaderController.onComplete = () => Main.setup();

    this.doCuchilloInfo();
    
    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
    } else {
      gsap.ticker.add(() => { Main.loop(); });
    }

    Preloader.init();
    LoaderController.init();
  }

  static setup() {
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);
  }

  static setupEvents() {
    EventDispatcher.addEventListener(Page.ON_SHOW, () => {
      // Cursor.start();
      Loading.stop();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE, () => {
      // Cursor.hide();
      // Sidemenu.hide();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
      Loading.start();
    });

    EventDispatcher.addEventListener(Win.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Win.ON_SHOW, () => { Scroll.setEnabled(false); });
    // EventDispatcher.addEventListener(Sidemenu.ON_HIDE, () => { Scroll.setEnabled(true); });
    // EventDispatcher.addEventListener(Sidemenu.ON_SHOW, () => {
    //   Scroll.setEnabled(false);
    //   ControllerWindow.hideAll();
    // });
  }

  static resize() {
    Header.resize();
    InterfaceCanvas.resize();
    BG.resize();
    ControllerPage.resize();

    if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static loop() {
    if (Scroll.isScrolling) Scroll.loop();

    BG.loop();
    Header.loop();
    InterfaceCanvas.loop();
  }

  static loopDebug() {
    Statics.begin();
    this.loop();
    Statics.end();
  }

  static doCuchilloInfo() {
    console.log('%cby Cuchillo', 'background: #000; color: #bada55; padding:25px 100px;');
    console.log('⟶ http://somoscuchillo.com');
    console.log('⟶ https://www.instagram.com/_cuchillo');
    console.log('⟶ https://twitter.com/somoscuchillo');
    console.log('⟶ https://twitter.com/mr__corrales');
    console.log('');
    console.log('Gsap by Greenshock');
    console.log('⟶ https://greensock.com');
    console.log('');
    console.log('Font: Fraktion Mono by Pangram Pangram');
    console.log('⟶ https://pangrampangram.com/products/fraktion');
    console.log('');
    console.log('SVGOMG');
    console.log('⟶ https://jakearchibald.github.io/svgomg/');
    console.log('');
    console.log('Favicon Generator');
    console.log('⟶ https://realfavicongenerator.net');
  }

  static setWorker() {
    if ('serviceWorker' in navigator && !isDebug) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function () { });
    }
  }
}

if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}
