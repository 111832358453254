import { GetBy } from '../_app/cuchillo/core/Element';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Cookies from '../_app/cuchillo/utils/Cookies';
import Default from './Default';

const COOKIE_NAME = 'eit-food';

export default class Programme extends Default {
  cookie;
  startups;

  constructor() {
    super();

    this.startups = GetBy.class('__startup', this.container);
    this.loadCookie();
  }

  loadCookie() {
    const cookie = Cookies.get(COOKIE_NAME);
    if (cookie) {
      console.log('Cookie existing', JSON.parse(cookie));
      this.cookie = JSON.parse(cookie);

      this.selectStartups();
    }
  }

  selectStartups() {
    const startups = this.cookie[0].startups;

    if (startups.length > 0) {
      for (let i = 0; i < this.startups.length; i++) {
        const startup = this.startups[i];
        const id = parseInt(startup.dataset.id);
        if (startups.filter(startup => startup === id).length > 0) startup.classList.add('--active');
      }
    }
  }
}

ControllerPage._addPage('programme', Programme);
