import { Basics } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import Cookies from '../_app/cuchillo/utils/Cookies';
import { FormValidatorEitFood } from '../form/FormValidatorEitFood';

const SERVER_NAME = 'https://eit-food-founders-day.cuchillo-black.tools';

class ScrollItem__Assistants extends VScroll_Item {
  rooms;
  startups;
  users = [];
  holder;
  holderRoom;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.init();

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  init() {
    this.holder = GetBy.class('__holder', this.item)[0];
    this.holderUser = GetBy.class('__holder-user', this.item)[0];
    this.holderRoom = GetBy.class('__holder-room', this.item)[0];
    this.getProjects();
  }

  async getProjects() {
    fetch(`${SERVER_NAME}/wp-json/wp/v2/projects?per_page=100&cachebuster=${new Date().getTime()}`)
      .then(response => response.json())
      .then(projects => {
        this.rooms = projects;

        this.getStartups();
      });
  }

  async getStartups() {
    fetch(`${SERVER_NAME}/wp-json/wp/v2/startups?per_page=100&cachebuster=${new Date().getTime()}`)
      .then(response => response.json())
      .then(startups => {
        this.startups = startups;

        startups.map(startup => {
          if (startup.acf.visitors) {
            startup.acf.visitors.map(visitor => {
              const user = this.users.filter(v => v.email === visitor.email);
              if (user.length === 0) {
                this.users.push({
                  email: visitor.email,
                  startups: [startup]
                });
              } else {
                user[0].startups.push(startup);
              }
            })
          }
        })

        // this.printRooms();
        // this.printByStartups();
        this.printByRoom();
      });
  }

  // printRooms() {
  //   this.rooms.map(project => {
  //     const div = document.createElement('div');
  //     this.holderRoom.appendChild(div);

  //     const h2 = document.createElement('h2');
  //     h2.innerHTML = project.title.rendered;
  //     div.appendChild(h2);

  //     const ul = document.createElement('ul');
  //     div.appendChild(ul);

  //     const startups = project.acf.blocks_custom[0].startups;
  //     startups.map(item => {
  //       const startup = this.startups.filter(su => su.id === item.startup.ID)[0];
  //       const li = document.createElement('li');
  //       li.innerHTML = startup.title.rendered;
  //       ul.appendChild(li);
  //     });
  //   });
  // }

  // printByStartups() {
  //   for (let i = 0; i < this.users.length; i++) {
  //     const user = this.users[i];

  //     const div = document.createElement('div');
  //     this.holder.appendChild(div);

  //     const h2 = document.createElement('h2');
  //     h2.innerHTML = user.email;
  //     div.appendChild(h2);

  //     const ul = document.createElement('ul');
  //     div.appendChild(ul);

  //     for (let j = 0; j < user.startups.length; j++) {
  //       const startup = user.startups[j];
  //       const li = document.createElement('li');
  //       li.innerHTML = startup.title.rendered;
  //       ul.appendChild(li);
  //     }
  //   }
  // }

  printByRoom() {
    for (let i = 0; i < this.users.length; i++) {
      const user = this.users[i];

      const div = document.createElement('div');
      this.holderUser.appendChild(div);

      const h2 = document.createElement('h2');
      h2.innerHTML = user.email;
      div.appendChild(h2);

      this.rooms.map(project => {
        let userStartups = [];
        const startups = project.acf.blocks_custom[0].startups;
        startups.map(item => {
          user.startups.map(su => {
            if (su.id === item.startup.ID) userStartups.push(su);
          })
        });

        if (userStartups.length > 0) {
          const h3 = document.createElement('h3');
          h3.innerHTML = project.title.rendered;
          div.appendChild(h3);

          const ul = document.createElement('ul');
          div.appendChild(ul);

          userStartups.map(startup => {
            const li = document.createElement('li');
            li.innerHTML = startup.title.rendered;
            ul.appendChild(li);
          });
        }
      });
    }
  }

  dispose() {
    super.dispose();
  }
}

Scroll._registerClass('widget-assistants', ScrollItem__Assistants);
