import { GetBy, C } from '../_app/cuchillo/core/Element';
import { WinMessage } from '../_app/cuchillo/windows/Message';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
// import FormSender from './FormSender';

const EMAIL_FILTER =
  /^([a-zA-Z0-9_\.\ñ\Ñ\-])+\@(([a-zA-Z0-9\-\ñ\Ñ])+\.)+([a-zA-Z0-9]{2,4})+$/;
const SERVER_NAME = 'https://eit-food-founders-day.cuchillo-black.tools';

export class FormValidatorEitFood {
  _form;
  _dataSend = {};
  callback;
  _fields = [];
  startups;

  constructor(__form, __callback) {
    this.callback = __callback !== undefined ? __callback : this.defaultCb;
    this._submit = (e) => {
      this.prepareSubmit(e);
    }
    this._input = (e) => {
      this.validate(e.target);
    };
    this._focus = (e) => {
      this.focus(e.target);
    };
    this._blur = (e) => {
      this.validate(e.target);
      this.focus(e.target, false);
    };

    this._form = __form;
    this._form.classList.remove('__form');
    this._form.addEventListener('submit', this._submit);

    this.getToken();
    this.getStartups();
    this.setupValidation();
  }

  setupValidation() {
    const items = [
      ...GetBy.selector('input', this._form),
      ...GetBy.selector('select', this._form),
      ...GetBy.selector('textarea', this._form)
    ];

    C.forEach(items, (item) => {
      this._fields.push(item);
      item.addEventListener('change', this._input);
      item.addEventListener('focus', this._focus);
      item.addEventListener('blur', this._blur);
    });
  }

  removeValidation() {
    C.forEach(this._fields, (item) => {
      item.removeEventListener('change', this._input);
      item.removeEventListener('focus', this._focus);
      item.removeEventListener('blur', this._blur);
    });
    this._fields = [];
  }

  focus(__input, focus = true) {
    __input.parentNode.classList[focus ? 'add' : 'remove']('--focus');
  }

  validate(__input) {
    if (!__input) return false;
    if (__input.disabled) return true;

    let valid = true;

    if (
      __input.dataset.formRequired !== undefined &&
      __input.value.split(' ').join('') === ''
    ) {
      valid = false;
    }

    if (
      __input.dataset.formEmail !== undefined &&
      !EMAIL_FILTER.test(__input.value)
    ) {
      valid = false;
    }

    if (__input.dataset.formCheckbox !== undefined && !__input.checked) {
      valid = false;
    }

    if (__input.dataset.formRadio !== undefined && !__input.checked) {
      valid = false;
    }

    if (__input.dataset.formPassword !== undefined) {
      const equal = GetBy.id(__input.dataset.formPassword);
      valid = equal.value === __input.value;
    }

    if (valid) {
      __input.parentNode.classList.remove('--error');
      __input.parentNode.classList.add('--success');
    } else {
      __input.parentNode.classList.add('--error');
      __input.parentNode.classList.remove('--success');
    }

    return valid;
  }

  check() {
    let valid = true;

    C.forEach(this._fields, (item) => {
      if (!this.validate(item)) {
        valid = false;
      } else {
        this._dataSend[item.getAttribute('name')] = item.value;
      }
    });

    return valid;
  }

  prepareSubmit(e) {
    e.preventDefault();

    if (this.check()) this.send();
  }

  async getToken() {
    fetch(`${SERVER_NAME}/wp-json/jwt-auth/v1/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      body: JSON.stringify({
        username: 'API USER',
        password: 'UxkU4em&DTuU9G*X18f&8Sx6'
      })
    })
      .then(response => {
        console.log(response);
        return response.json();
      }).then(user => {
        console.log('SUCCESS', user);
        localStorage.setItem('jwt', user.token)
      });
  }

  async getStartups() {
    fetch(`${SERVER_NAME}/wp-json/wp/v2/startups?per_page=100&cachebuster=${new Date().getTime()}`)
      .then(response => response.json())
      .then(startups => {
        this.startups = startups;
        this._form.removeAttribute('disabled');
      });
  }

  async addVisitor() {
    const id = parseInt(this._dataSend.startup_id);
    const email = this._dataSend.email;
    const startup = this.startups.filter(su => su.id === id)[0];

    // console.log('Has visitors', startup.acf.visitors, typeof startup.acf.visitors, typeof startup.acf.visitors === 'object');
    // console.log('Has visitors', startup);
    // console.log('Test', startup.fields);

    let visitors = [];
    if (startup.acf.visitors !== null) {
      visitors = startup.acf.visitors;

      if (visitors.filter(visitor => {
        console.log('Filtering emails', visitor, email);
        return visitor.email === email
      }).length > 0) {
        console.log('Email Already visited this startup');
        return 200;
      }
    } else {
      console.log('Startup First visitor');
    }

    visitors.push({
      "email": email,
      "timestamp": Date.now()
    });

    const payload = {
      'fields': {
        ...startup.acf,
        "visitors": visitors,
        "country": 'Catalunya'
      }
    };

    return fetch(`${SERVER_NAME}/wp-json/wp/v2/startups/${id}?cachebuster=${Date.now()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        console.log('Response status:', response.status);
        return response.json();
      })
      .then(data => {
        console.log('Response data:', data);
        if (data && data.id) {
          console.log('Update successful');
          return 200;
        } else {
          console.log('Update failed');
          return 500;
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return 500;
      });
  }

  async send() {
    this._form.setAttribute('disabled', 'true');

    const cb = status => {
      this._form.removeAttribute('disabled');
      this.callback(status);
    }

    await this.addVisitor()
      .then(status => cb(status))
      .catch(status => cb(status));
  }

  reset() {
    this._dataSend = {};

    C.forEach(this._fields, (item) => {
      item.value = '';
      item.checked = false;
      item.removeAttribute('checked');
      item.parentNode.classList.remove('--error');
      item.parentNode.classList.add('--success');
    });

    this._form.classList.remove('--sending');
  }

  dispose() {
    this._form.removeEventListener('submit', this._submit);
    this.removeValidation();
  }

  refresh() {
    this.removeValidation();
    this.setupValidation();
  }

  defaultCb = status => {
    const linkThanks = this._form.dataset.mssgOk.startsWith('/') ? this._form.dataset.mssgOk : null;
    this._form.classList.remove('--sending');

    if (status === 200) {
      if (linkThanks) {
        ControllerPage.changePage(linkThanks);
      } else {
        WinMessage.success(this._form.dataset.mssgOk);
        this.reset();
      }
    } else {
      WinMessage.error(this._form.dataset.mssgNok);
    }
  };
}
