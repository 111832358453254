import { Basics } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import Cookies from '../_app/cuchillo/utils/Cookies';
import { FormValidatorEitFood } from '../form/FormValidatorEitFood';

const COOKIE_NAME = 'eit-food';
// const SERVER_NAME = 'https://eit-food-founders-day.cuchillo-black.tools';

class ScrollItem__Form extends VScroll_Item {
  cookie;
  formValidator;
  form;
  email;
  checkbox;
  startupId;
  cta;
  startup;

  _cb = status => this.cb(status);

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.init();

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  init() {
    this.email = GetBy.selector('[name="email"]', this.item)[0];
    this.checkbox = GetBy.selector('[type="checkbox"]', this.item)[0];
    this.startupId = parseInt(GetBy.selector('[name="startup_id"]', this.item)[0].value);
    this.cta = GetBy.class('__submit', this.item)[0];
    this.form = GetBy.class('__form', this.item)[0];

    this.formValidator = new FormValidatorEitFood(this.form, this._cb);

    this.loadCookie();
  }

  loadCookie() {
    const cookie = Cookies.get(COOKIE_NAME);
    if (cookie) {
      console.log('Cookie existing', JSON.parse(cookie));
      this.cookie = JSON.parse(cookie);
      this.email.value = this.cookie[0].email;
      this.checkbox.checked = 'true';

      this.formValidator.check();
    }
  }

  storeCookie() {
    if (!this.cookie) {
      console.log('NO COOKIE');
      this.cookie = [{
        email: this.email.value,
        startups: [this.startupId]
      }];
    } else {
      console.log('COOKIE', this.cookie);
      let existing = false;
      this.cookie.map(user => {
        if (user.email === this.email.value) {
          console.log('Existing user', user);

          existing = true;

          if (user.startups.indexOf(this.startupId) === -1) {
            const su = user.startups;
            user.startups = [...su, this.startupId];
            console.log('Not existing company');
          } else {
            console.log('Existing company');
          }
        }
      });

      if (!existing) {
        console.log('Not existing user');
        this.cookie.push({
          email: this.email.value,
          startups: [this.startupId]
        });
      }
    }

    // if (!this.cookie) {
    //   console.log('NO COOKIE');
    //   this.cookie = {
    //     email: this.email.value,
    //     startups: [this.startupId]
    //   };
    // } else if (this.cookie.startups.indexOf(this.startupId) === -1) {
    //   const su = this.cookie.startups;
    //   this.cookie.startups = [...su, this.startupId];
    // } else {
    //   console.log('Existing company');
    // }

    Cookies.add(COOKIE_NAME, JSON.stringify(this.cookie));
  }

  cb(status) {
    console.log('STATUS', status);

    if (status === 200) {
      this.storeCookie();
      this.form.classList.remove('--sending');
      this.item.classList.add('--good');

      setTimeout(() => {
        ControllerPage.changePage('/');
      }, 3500)
    } else {
      this.item.classList.add('--fail');
    }
  }

  dispose() {
    super.dispose();
    this.formValidator.dispose();
  }
}

Scroll._registerClass('widget-form', ScrollItem__Form);
